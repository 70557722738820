import { map, union, isEqual } from 'lodash';
import {
  fetchCompetitionSubscription,
  fetchEventSubscription,
  bookEvent,
  bookSameGameParlays,
  bookPlayerProps,
  bookMicroMarkets,
  unbookEvent,
  unbookSameGameParlays,
  unbookPlayerProps,
  unbookMicroMarkets,
  subscribe,
  subscribeSameGameParlays,
  subscribePlayerProps,
  subscribeMicroMarkets,
  unsubscribe,
  unsubscribeSameGameParlays,
  unsubscribePlayerProps,
  unsubscribeMicroMarkets,
  updateCompetitionFeedConfiguration,
} from '@/services/api';

export const subscriptionTypes = {
  COMPETITION: 'COMPETITION',
  EVENT: 'EVENT',
};

export const pricingModeTypes = {
  AUTO: 'AUTO',
};

export const marketCategoryTypes = {
  CORE_AND_DERIVATIVES: 'CORE_AND_DERIVATIVES',
  SAME_GAME_PARLAYS: 'SAME_GAME_PARLAYS',
  PLAYER_PROPS: 'PLAYER_PROPS',
  MICROS: 'MICROS',
};

export const allMarketCategories = {
  [marketCategoryTypes.CORE_AND_DERIVATIVES]: {
    id: marketCategoryTypes.CORE_AND_DERIVATIVES,
    label: 'Core & derivatives',
  },
  [marketCategoryTypes.SAME_GAME_PARLAY]: {
    id: marketCategoryTypes.SAME_GAME_PARLAYS,
    label: 'Same game parlay',
  },
  [marketCategoryTypes.PLAYER_PROPS]: {
    id: marketCategoryTypes.PLAYER_PROPS,
    label: 'Player props',
  },
  [marketCategoryTypes.MICROS]: {
    id: marketCategoryTypes.MICROS,
    label: 'Micro markets',
  },
};

export const allPricingModes = {
  [pricingModeTypes.AUTO]: {
    id: pricingModeTypes.AUTO,
    label: 'Mercury',
    shortLabel: 'Mercury',
  },
};

export const makeCompetitionSubscriptionRecord = (competition) => ({
  id: competition.competitionId,
  pricingMode: competition.core.nodes?.[0]?.pricingMode || '',
  core: !!competition.core.totalCount,
  sameGameParlays: !!competition.sameGameParlays.totalCount,
  playerProps: !!competition.playerProps.totalCount,
  micro: !!competition.micro.totalCount,
  marketCategories: union(...map(
    competition.feedConfigurations.nodes || [],
    (feedConfiguration) => feedConfiguration.marketCategories,
  )),
  feedPriorities: competition.feedConfigurations.nodes || [],
  isBooked: !!competition.core?.totalCount,
  offeringByGameState: {
    core: {
      type: marketCategoryTypes.CORE_AND_DERIVATIVES,
      isOfferedPreMatch: !!competition.core.nodes?.[0]?.isOfferedPreMatch,
      isOfferedLive: !!competition.core.nodes?.[0]?.isOfferedLive,
    },
    sameGameParlays: {
      type: marketCategoryTypes.SAME_GAME_PARLAYS,
      isOfferedPreMatch: !!competition.sameGameParlays.nodes?.[0]?.isOfferedPreMatch,
      isOfferedLive: !!competition.sameGameParlays.nodes?.[0]?.isOfferedLive,
    },
    playerProps: {
      type: marketCategoryTypes.PLAYER_PROPS,
      isOfferedPreMatch: !!competition.playerProps.nodes?.[0]?.isOfferedPreMatch,
      isOfferedLive: !!competition.playerProps.nodes?.[0]?.isOfferedLive,
    },
    micro: {
      type: marketCategoryTypes.MICROS,
      isOfferedPreMatch: !!competition.micro.nodes?.[0]?.isOfferedPreMatch,
      isOfferedLive: !!competition.micro.nodes?.[0]?.isOfferedLive,
    },
  },
});

export const makeEventSubscriptionRecord = (event) => ({
  id: event.eventId,
  pricingMode: event.core.nodes?.[0]?.pricingMode || '',
  core: !!event.core.totalCount,
  sameGameParlays: !!event.sameGameParlays.totalCount,
  playerProps: !!event.playerProps.totalCount,
  micro: !!event.micro.totalCount,
  marketCategories: union(...map(
    event.competition.feedConfigurations.nodes || [],
    (feedConfiguration) => feedConfiguration.marketCategories,
  )),
  feedPriorities: [],
  isBooked: !!event.core?.totalCount,
  offeringByGameState: {
    core: {
      type: marketCategoryTypes.CORE_AND_DERIVATIVES,
      isOfferedPreMatch: !!event.core.nodes?.[0]?.isOfferedPreMatch,
      isOfferedLive: !!event.core.nodes?.[0]?.isOfferedLive,
    },
    sameGameParlays: {
      type: marketCategoryTypes.SAME_GAME_PARLAYS,
      isOfferedPreMatch: !!event.sameGameParlays.nodes?.[0]?.isOfferedPreMatch,
      isOfferedLive: !!event.sameGameParlays.nodes?.[0]?.isOfferedLive,
    },
    playerProps: {
      type: marketCategoryTypes.PLAYER_PROPS,
      isOfferedPreMatch: !!event.playerProps.nodes?.[0]?.isOfferedPreMatch,
      isOfferedLive: !!event.playerProps.nodes?.[0]?.isOfferedLive,
    },
    micro: {
      type: marketCategoryTypes.MICROS,
      isOfferedPreMatch: !!event.micro.nodes?.[0]?.isOfferedPreMatch,
      isOfferedLive: !!event.micro.nodes?.[0]?.isOfferedLive,
    },
  },
});

export const makeSubscriptionRecord = (type, entity) => {
  switch (type) {
  case subscriptionTypes.COMPETITION:
    return makeCompetitionSubscriptionRecord(entity);
  case subscriptionTypes.EVENT:
    return makeEventSubscriptionRecord(entity);
  default:
    return {};
  }
};

export const fetchSubscriptionRecord = async (type, id) => {
  switch (type) {
  case subscriptionTypes.COMPETITION:
    const { data: { competition } } = await fetchCompetitionSubscription(id);
    if (!competition) return null;
    return makeCompetitionSubscriptionRecord(competition);
  case subscriptionTypes.EVENT:
    const { data: { event } } = await fetchEventSubscription(id);
    if (!event) return null;
    return makeEventSubscriptionRecord(event);
  default:
    return {};
  }
};

export const saveCoreAndDerivatives = (type, originalRecord, newRecord) => {
  if (
    originalRecord.core === newRecord.core
    && isEqual(originalRecord.offeringByGameState.core, newRecord.offeringByGameState.core)
  ) return Promise.resolve();
  if (type === subscriptionTypes.EVENT) {
    if (newRecord.core) {
      return bookEvent({
        eventId: newRecord.id,
        isOfferedPreMatch: newRecord.offeringByGameState.core.isOfferedPreMatch,
        isOfferedLive: newRecord.offeringByGameState.core.isOfferedLive,
      });
    }
    return unbookEvent(newRecord.id);
  }
  if (type === subscriptionTypes.COMPETITION) {
    if (newRecord.core) {
      return subscribe({
        competitionId: newRecord.id,
        isOfferedPreMatch: newRecord.offeringByGameState.core.isOfferedPreMatch,
        isOfferedLive: newRecord.offeringByGameState.core.isOfferedLive,
      });
    }
    return unsubscribe(newRecord.id);
  }
  return Promise.resolve();
};

export const saveSameGameParlays = (type, originalRecord, newRecord) => {
  if (
    originalRecord.sameGameParlays === newRecord.sameGameParlays
    && isEqual(originalRecord.offeringByGameState.sameGameParlays, newRecord.offeringByGameState.sameGameParlays)
  ) return Promise.resolve();
  if (type === subscriptionTypes.EVENT) {
    if (newRecord.sameGameParlays) {
      return bookSameGameParlays({
        eventId: newRecord.id,
        isOfferedPreMatch: newRecord.offeringByGameState.sameGameParlays.isOfferedPreMatch,
        isOfferedLive: newRecord.offeringByGameState.sameGameParlays.isOfferedLive,
      });
    }
    return unbookSameGameParlays(newRecord.id);
  }
  if (type === subscriptionTypes.COMPETITION) {
    if (newRecord.sameGameParlays) {
      return subscribeSameGameParlays({
        competitionId: newRecord.id,
        isOfferedPreMatch: newRecord.offeringByGameState.sameGameParlays.isOfferedPreMatch,
        isOfferedLive: newRecord.offeringByGameState.sameGameParlays.isOfferedLive,
      });
    }
    return unsubscribeSameGameParlays(newRecord.id);
  }
  return Promise.resolve();
};

export const savePlayerProps = (type, originalRecord, newRecord) => {
  if (
    originalRecord.playerProps === newRecord.playerProps
    && isEqual(originalRecord.offeringByGameState.playerProps, newRecord.offeringByGameState.playerProps)
  ) return Promise.resolve();
  if (type === subscriptionTypes.EVENT) {
    if (newRecord.playerProps) {
      return bookPlayerProps({
        eventId: newRecord.id,
        isOfferedPreMatch: newRecord.offeringByGameState.playerProps.isOfferedPreMatch,
        isOfferedLive: newRecord.offeringByGameState.playerProps.isOfferedLive,
      });
    }
    return unbookPlayerProps(newRecord.id);
  }
  if (type === subscriptionTypes.COMPETITION) {
    if (newRecord.playerProps) {
      return subscribePlayerProps({
        competitionId: newRecord.id,
        isOfferedPreMatch: newRecord.offeringByGameState.playerProps.isOfferedPreMatch,
        isOfferedLive: newRecord.offeringByGameState.playerProps.isOfferedLive,
      });
    }
    return unsubscribePlayerProps(newRecord.id);
  }
  return Promise.resolve();
};

export const saveMicros = (type, originalRecord, newRecord) => {
  if (
    originalRecord.micro === newRecord.micro
    && isEqual(originalRecord.offeringByGameState.micro, newRecord.offeringByGameState.micro)
  ) return Promise.resolve();
  if (type === subscriptionTypes.EVENT) {
    if (newRecord.micro) {
      return bookMicroMarkets({
        eventId: newRecord.id,
        isOfferedPreMatch: newRecord.offeringByGameState.micro.isOfferedPreMatch,
        isOfferedLive: newRecord.offeringByGameState.micro.isOfferedLive,
      });
    }
    return unbookMicroMarkets(newRecord.id);
  }
  if (type === subscriptionTypes.COMPETITION) {
    if (newRecord.micro) {
      return subscribeMicroMarkets({
        competitionId: newRecord.id,
        isOfferedPreMatch: newRecord.offeringByGameState.micro.isOfferedPreMatch,
        isOfferedLive: newRecord.offeringByGameState.micro.isOfferedLive,
      });
    }
    return unsubscribeMicroMarkets(newRecord.id);
  }
  return Promise.resolve();
};

export const saveFeedPriorities = (type, originalRecord, newRecord) => {
  if (isEqual(originalRecord.feedPriorities, newRecord.feedPriorities)) return Promise.resolve();
  return updateCompetitionFeedConfiguration(newRecord.feedPriorities);
};

export const saveSubscriptions = (type, originalRecord, newRecord) => Promise.all([
  saveCoreAndDerivatives(type, originalRecord, newRecord),
  saveSameGameParlays(type, originalRecord, newRecord),
  savePlayerProps(type, originalRecord, newRecord),
  saveMicros(type, originalRecord, newRecord),
  saveFeedPriorities(type, originalRecord, newRecord),
]);
